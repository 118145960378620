.content-rating-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); // 5 colonnes par ligne
    gap: 12px; // Espacement entre les cartes
    justify-content: center;
    .content-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        height: auto;
        border: #ddd solid 0.5px;
        padding: 4px;
        &.active{
            // border: #313fff solid 3px;
            box-shadow: 0 0 0px 3px #313fff;
        }
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 100%;
        background: #000;
        border-radius: 10px;
        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            object-fit: contain; // Ajuste l'image pour qu'elle remplisse correctement
        }

        .fullscreen-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: rgba(35, 35, 35, 0.8);
            color: #FFF;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            &:focus {
                outline: none;
            }
        }
        .info-button {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background: rgba(35, 35, 35, 0.8);
            color: #FFF;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            &:focus {
                outline: none;
            }
        }
        .gender-button {
            position: absolute;
            top: 10px;
            left: 10px;
            background: rgba(145, 145, 145, 0.8);
            color: #FFF;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;
            font-weight: bold;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            &:focus {
                outline: none;
            }
            &.man{
                //background: #313fff;
                background: rgba(73, 85, 255, 0.664);
            }
            &.women{
                //background: #ff0080;
                background: rgba(255, 41, 141, 0.541);
            }
            &.couple{
                //background: #db4aff;
                background: rgba(222, 110, 250, 0.699);
            }
            &.trave{
                //background: #fffb00;
                background: rgba(249, 253, 24, 0.699);
                color: #000;
            }
        }
        .content-rating-button {
            position: absolute;
            top: 10px;
            left: 10px;
            color: #fff;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

            &.sw{
                background: #0FE367;
            }
            &.erotic{
                background: #FFCC00;
            }
            &.explicit{
                background: #FF9500;
            }
            &.extrem{
                background: #FF3636;
            }
        }
    }
}

@media screen and (max-width: 1000px) { 
    .content-rating-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); // 2 colonnes par ligne
    }
}

@media screen and (max-width: 720px) { 
    .content-rating-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // 2 colonnes par ligne
    }
}